<template>
  <section class="metallurgy">
    <general-second :name="page.pageName" :descript="page.description"></general-second>
    <!--<MetalProcessing></MetalProcessing>
    <metal-cutting class="gray"></metal-cutting>
    <metal-working-machines></metal-working-machines> -->
    <h2 class="center" style="margin: 100px 0px;">Site is under construction</h2>
  </section>
</template>

<script>
import GeneralSecond from '@/components/GeneralSecond'
/* import MetalCutting from '@/components/metallurgy/MetalCutting'
import MetalProcessing from '@/components/metallurgy/MetalProcessing'
import MetalWorkingMachines from '@/components/metallurgy/MetalWorkingMachines' */
export default {
  name: 'Metallurgy',
  components: {
    GeneralSecond
    /* MetalWorkingMachines,
    MetalProcessing,
    MetalCutting */
  },
  data () {
    return {
      page: {
        pageName: 'Metallurgy',
        description: ''
      }
    }
  },
  metaInfo: {
    title: 'Metallurgy',
    titleTemplate: '%s - VERTEX GmbH',
    htmlAttrs: {
      lang: 'en',
      amp: true
    }
  }
}
</script>

<style>

</style>
